import request from '@/utils/request'
export function getWorkplace () {
  return request({
    url: '/workplace',
    method: 'get'
  })
}
export function getUnit () {
  return request({
    url: '/unit',
    method: 'get'
  })
}
export function getJob () {
  return request({
    url: '/job',
    method: 'get'
  })
}
export function getCourseTitle () {
  return request({
    url: '/course/title',
    method: 'get'
  })
}
export function getSurveysTitle () {
  return request({
    url: '/surveys/title',
    method: 'get'
  })
}
export function getUser () {
  return request({
    url: '/course/users',
    method: 'get'
  })
}
