<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" >
      <el-col :lg="8" :md="8" :sm="14" :xs="14" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Buscar" v-model="searchForm.query" v-on:change="handleSearch()" clearable></el-input>
      </el-col>
      <!-- <el-col :lg="2" :xs="8" :offset="0"> -->
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleSearch"
          type="primary"
          icon="el-icon-search"
          size="mini"
        >Buscar</el-button>
      <!-- </el-col> -->
      <!-- <el-col :lg="2" :xs="8" :offset="0"> -->
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          type="warning"
          icon="el-icon-delete"
          size="mini"
        >Limpiar</el-button>
      <!-- </el-col>
      <el-col :lg="2" :xs="8" :offset="0"> -->
        <el-button
          class="margin-left-10"
          @click.stop.prevent="exportExcelFile"
          type="success"
          icon="el-icon-bottom"
          size="mini"
        >Exportar Excel</el-button>
      <!-- </el-col>
      <el-col :lg="8" :xs="8" :offset="0"> -->
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleCreatedHtml"
          type="success"
          icon="el-icon-circle-plus-outline"
          size="mini"
        >HTML</el-button>
      <!-- </el-col>
      <el-col :lg="2" :xs="8" :offset="0"> -->
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleCreatedPdf"
          type="success"
          icon="el-icon-circle-plus-outline"
          size="mini"
        >PDF</el-button>
      <!-- </el-col>
      <el-col :lg="2" :xs="8" :offset="0"> -->
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleCreatedAudio"
          type="success"
          icon="el-icon-circle-plus-outline"
          size="mini"
        >Audio</el-button>
      <!-- </el-col>
      <el-col :lg="2" :xs="8" :offset="0"> -->
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleCreatedVideo"
          type="success"
          icon="el-icon-circle-plus-outline"
          size="mini"
        >Video</el-button>
      <!-- </el-col> -->
    </el-row>
     <el-row :gutter="20">
      <el-col :lg="5" :sm="24" :offset="0">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Filtro</span> <br>
          <el-select
            size="mini"
            v-model="searchForm.status"
            filterable
            placeholder="Ninguno"
            v-on:change="handleSearch()"
            clearable>
              <el-option
                v-for="itemT in publishOptions"
                :key="itemT.value"
                :label="itemT.label"
                :value="itemT.value">
              </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :lg="5" :sm="24" :offset="0">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Curso</span> <br>
         <el-select
                  class="width-100"
                  size="mini"
                  v-model="searchForm.course_id"
                  filterable
                  v-on:change="handleSearch()"
                  clearable
                  placeholder="título">
                    <el-option
                      v-for="itemCourses in listItemsCoursesTitle"
                      :key="itemCourses.value"
                      :label="itemCourses.title"
                      :value="itemCourses._id">
                    </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
        <el-pagination small @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
      style="width: 100%"
      size="mini"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <p>ID: {{ props.row._id }}</p>
          <p>Duración: {{ props.row.duration }}</p>
          <div v-html="props.row.description"></div>
        </template>
      </el-table-column>
      <el-table-column label="Título" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
       <el-table-column label="Curso" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.titleResource">{{ scope.row.titleResource.title }}</span>
          <span v-else>Sin título</span>
        </template>
      </el-table-column>
      <el-table-column label="Tipo" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Orden" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.order }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Publicado" align="center">
          <div slot-scope="scope" v-if="scope.row.status=== true">
            Publicado
        </div>
        <div v-else>
             No publicado
        </div>
      </el-table-column>
      <el-table-column label="Fecha Alta" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.updated_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" width="230" align="center">
          <template slot-scope="scope" >
            <el-button
              @click.stop.prevent="handleEdit(scope.$index, scope.row)"
              size="mini"
              type="primary"
              round
            >Editar <i class="el-icon-arrow-right el-icon-circle-plus-outline"></i></el-button>
            <el-button
              @click.stop.prevent="handleDelete(scope.$index, scope.row)"
              size="mini"
              type="danger"
              round
            >Eliminar <i class="el-icon-arrow-right el-icon-remove-outline"></i></el-button>
            <!-- <span>{{ scope.row.deviceLoggersLast }}</span> -->
          </template>
        </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { search, getPage, destroyResources, exportExcel } from '@/api/resources' // updateCourses, destroyCourses
import { getCourseTitle } from '@/api/listItems'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        course_id: '',
        status: ''
      },
      listItemsCoursesTitle: [],
      publishOptions: [{
        value: 'true',
        label: 'Publicado'
      }, {
        value: 'false',
        label: 'No publicado'
      }],
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false
    }
  },
  mounted () {
    this.handleSearch()
    this.listCoursesTitle()
  },
  methods: {
    async handleSearch () {
      this.loading = true
      await search(this.searchForm)
        .then(response => {
          // console.log(response.data)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listCoursesTitle () {
      this.loading = true
      await getCourseTitle()
        .then(response => {
          console.log(response)
          this.listItemsCoursesTitle = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPage(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCreatedHtml () {
      this.$router.push({ name: 'resources.create.html' })
    },
    async handleCreatedPdf () {
      this.$router.push({ name: 'resources.create.pdf' })
    },
    async handleCreatedAudio () {
      this.$router.push({ name: 'resources.create.audio' })
    },
    async handleCreatedVideo () {
      this.$router.push({ name: 'resources.create.video' })
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.status = ''
      this.searchForm.course_id = ''
      this.handleSearch()
    },
    handleDelete (index, row) {
      this.loadingDelete = true
      this.$confirm('¿Se borrará permanentemente. Continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await destroyResources(row._id)
            .then(response => {
              // let res = response.data
              this.$message({
                showClose: true,
                message: 'Se eliminó correctamente!',
                type: 'success'
              })
              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
        })
        .finally(() => (this.loadingDelete = false))
    },
    async exportExcelFile () {
      this.loading = true
      await exportExcel(this.searchForm)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          const name = 'Reporte_de_recursos_de_curso_' + new Date() + '.xlsx'
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleEdit (index, row) {
      console.log(row)
      this.$router.push({ name: 'resources.edit', params: { _id: row._id } })
    }
  }
}
</script>
